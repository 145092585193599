import { limiteTamanhoArquivo } from './constants';
import moment from 'moment';

const isCpfCnpj = (value) => {
  if (value) {
    value = value.replace(/\D/g,'');
    if (value.length <= 11) {
      return isCPF(value)
    } else {
      return isCNPJ(value)
    } 
  } 
}

const isCPF = (number) => {
  let sum;
  let rest;
  sum = 0;

  // eslint-disable-next-line no-useless-escape
  number = number.replace(/\-|\./g, '');
  if (number === "00000000000") return false;
  if (number === "99999999999") return false;

  for (let i=1; i<=9; i++) sum = sum + parseInt(number.substring(i-1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))  rest = 0;
  if (rest !== parseInt(number.substring(9, 10)) ) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(number.substring(i-1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))  rest = 0;
  if (rest !== parseInt(number.substring(10, 11) ) ) return false;
  return true;
}

const isDate = (condition, value1, value2, message) => {

let v1 = value1.split("/");
value1 = new Date(v1[2] + "-" + v1[1] + "-" + v1[0]);

if (value1 instanceof Date)
  switch (condition) {
    case ">":  return value1 <= value2 ? {message: message} : true;
    case ">=":  return value1 < value2 ? {message: message} : true;
    case "<":  return value1 >= value2 ? {message: message} : true;
    case "<=":  return value1 > value2 ? {message: message} : true;
    case "===":  return value1 !== value2 ? {message: message} : true;
    case "<>":  return value1 === value2 ? {message: message} : true;
    default: return true;
  }
else
  return {message: "Data inválida."}
}

export const isCNPJ = (cnpj) => {
  let tamanho, numeros, digitos, soma, pos, resultado = null;
  cnpj = cnpj.replace(/[^\d]+/g,'');
  if(cnpj === '') return {message: "CNPJ inválido."}; 
  if (cnpj.length !== 14)
  return {message: "CNPJ inválido."}; 
  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" || 
      cnpj === "11111111111111" || 
      cnpj === "22222222222222" || 
      cnpj === "33333333333333" || 
      cnpj === "44444444444444" || 
      cnpj === "55555555555555" || 
      cnpj === "66666666666666" || 
      cnpj === "77777777777777" || 
      cnpj === "88888888888888" || 
      cnpj === "99999999999999")
      return {message: "CNPJ inválido."};   
  // Valida DVs
  tamanho = cnpj.length - 2
  numeros = cnpj.substring(0,tamanho);
  digitos = cnpj.substring(tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado.toString() !== digitos.charAt(0))
      return {message: "CNPJ inválido."};
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado.toString() !== digitos.charAt(1))
    return {message: "CNPJ inválido."};
         
  return true;
  
}

const isPhone = (value) => {
  value = value.replace(/\D/g,'');
  if (value.length >= 10 && !allEqual(value.split(""))) {
    return true;
  }

  return false;
}

const isNullOrEmptyOrUndefined = (value) => {
  var result = value === '' || value === null || value === undefined;
  return result;
}

const allEqual = arr => arr.every( v => v === arr[0] )

const validarTamanhoArq = (file) => {
  //1mb = 1048576
  const Limite = 5242880;
  return file.size > Limite;
}

const isDateValidExpirationCard = (strDate) => {
  try {
   
    const date = moment(strDate, "MM/YY", true);
    if (!date.isValid())
      return false;

    let yearNow = moment().format("YY");

    let splitDate = strDate.split("/");
    const month = parseInt(splitDate[0]);
    const year = parseInt(splitDate[1]);
    if (month < 1 || month > 12 || year < parseInt(yearNow))
      return false;

    return true;
  } catch (e) {
     return false;
  } 
}

export default {
  isDate: isDate,
  validarTamanhoArq: validarTamanhoArq,
  isPhone: isPhone,
  isCpfCnpj: isCpfCnpj,
  isDateValidExpirationCard: isDateValidExpirationCard,
  isCPF: isCPF,
  isCNPJ: isCNPJ,
  isNullOrEmptyOrUndefined
}


